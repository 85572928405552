import React from 'react'
import { graphql, Link } from 'gatsby'
//import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'
import ContactForm from '../components/contact-form'

const ProductTemplate = ({
  data: {
    wpPost: { slug, title, content, featuredImage },
  },
}) => {
  // const image = getImage(
  //   featuredImage.node.localFile.childImageSharp.gatsbyImageData
  // )
  // const src = getSrc(
  //   featuredImage.node.localFile.childImageSharp.gatsbyImageData
  // )

  //let myurl = 'https://www.server.amerex.mx/pdf-meci/' + slug + '.pdf'

  return (
    <Layout>
      <Seo title={title} />
      <div className='d-flex flex-md-nowrap flex-wrap my-md-5 my-3 px-5 gap-3 justify-content-center'>
        <div className='col-12 col-md-4 mb-4 mb-md-0 text-center'>
          <img
            className='img-fluid mb-3 p-3 bg-white rounded'
            alt={featuredImage.node.altText}
            src={featuredImage.node.sourceUrl}
            srcSet={featuredImage.node.srcSet}
          />
          <Link
            className='btn btn-orange btn-lg ms-3 mt-3 mt-md-0'
            to='#hero-form'
          >
            SOLICITAR INFORMACIÓN
          </Link>
          {/* <GatsbyImage
            className='m-3'
            image={image}
            content={src}
            // image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
            alt={featuredImage.node.altText}
          /> */}
          {/* <a
            className='btn btn-danger btn-lg'
            href={myurl}
            target='_blank'
            rel='noopener noreferrer'
          >
            DESCARGAR PDF
          </a> */}
        </div>
        <div id='products-info' className='col-12 col-md-6'>
          <h1 className='fs-2 fw-bold text-site-orange text-uppercase'>
            {title}
          </h1>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </div>
      <ContactForm />
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      slug
      featuredImage {
        node {
          sourceUrl
          srcSet
          altText
        }
      }
    }
  }
`

export default ProductTemplate
